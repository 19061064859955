import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-10 p-10 generation-mix-table-section card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleTable = _resolveComponent("SimpleTable")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.historical_mode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.generationMixTableList)

          .slice(-3)
          .reverse(), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "table-responsive mt-4",
              key: index
            }, [
              _createVNode(_component_SimpleTable, {
                tableData: _ctx.generationMixTableList[item]
              }, null, 8, ["tableData"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SimpleTable, { tableData: _ctx.tableData }, null, 8, ["tableData"])
          ])
        ]))
  ]))
}