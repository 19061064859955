import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10 p-10 compare-section" }
const _hoisted_2 = { class: "mb-10 print-mt-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "generation-mix-chart col-8 p-5" }
const _hoisted_5 = { class: "col-4 p-10" }
const _hoisted_6 = { class: "mb-3 card p-10" }
const _hoisted_7 = { class: "d-flex justify-content-between align-items-center w-100" }
const _hoisted_8 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_9 = { class: "form-check form-switch form-check-custom form-check-solid" }
const _hoisted_10 = ["aria-label"]
const _hoisted_11 = { class: "mx-auto w-75 p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_GenerationTable = _resolveComponent("GenerationTable")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.translate("REPORT_III_GENERATION_MIX_HEADER")), 1),
    _createElementVNode("h5", null, _toDisplayString(_ctx.translate("REPORT_III_GENERATION_MIX_LABEL")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.historical_mode)
          ? (_openBlock(), _createBlock(_component_BarChart, {
              key: 0,
              chartData: _ctx.chartData,
              options: _ctx.option,
              height: 500
            }, null, 8, ["chartData", "options"]))
          : (_openBlock(), _createBlock(_component_BarChart, {
              key: 1,
              chartData: _ctx.chartDataForMultiYear,
              options: _ctx.optionForMultiYear,
              height: 500
            }, null, 8, ["chartData", "options"]))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.translate("REPORT_HISTORIC_SELECT_LABEL")), 1),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                class: "form-check-input",
                name: "billing",
                type: "checkbox",
                value: "1",
                id: "kt_modal_add_customer_billing",
                checked: "checked",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.historical_mode) = $event)),
                "aria-label": _ctx.translate('REPORT_HISTORIC_SELECT_LABEL')
              }, null, 8, _hoisted_10), [
                [_vModelCheckbox, _ctx.historical_mode]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_GenerationTable, { historical_mode: _ctx.historical_mode }, null, 8, ["historical_mode"])
      ])
    ])
  ]))
}