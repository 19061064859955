
import { defineComponent } from "vue";
import { translate, translateKey } from "@/hooks/TranslateService";

export default defineComponent({
  name: "CompareResult",
  props: ["tableData"],
  setup() {
    return {
      translate,
      translateKey,
    };
  },
});
