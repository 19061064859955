import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-10 p-10 compare-section card"
}
const _hoisted_2 = { class: "mb-10 print-mt-0 text-uppercase" }
const _hoisted_3 = { class: "row container-bar-chart" }
const _hoisted_4 = {
  key: 0,
  class: "bar-chart col-md-8 col-sm-12 print-w-75"
}
const _hoisted_5 = {
  key: 1,
  class: "bar-chart col-md-8 col-sm-12 print-w-75"
}
const _hoisted_6 = { class: "compare-your-result col-md-4 col-sm-12 print-w-25 p-10" }
const _hoisted_7 = { class: "card p-10 content-section" }
const _hoisted_8 = { class: "fs-3 fw-bold form-label mb-3" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_11 = ["label"]
const _hoisted_12 = ["value", "label"]
const _hoisted_13 = { class: "mb-3" }
const _hoisted_14 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_15 = ["label"]
const _hoisted_16 = ["value", "label"]
const _hoisted_17 = { class: "mb-3" }
const _hoisted_18 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_19 = ["label"]
const _hoisted_20 = ["value", "label"]
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_23 = ["label"]
const _hoisted_24 = ["label"]
const _hoisted_25 = ["label"]
const _hoisted_26 = { class: "mb-3" }
const _hoisted_27 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_28 = ["label"]
const _hoisted_29 = ["label"]
const _hoisted_30 = ["label"]
const _hoisted_31 = { class: "mb-3" }
const _hoisted_32 = { class: "fs-5 fw-bold form-label mb-1" }
const _hoisted_33 = ["label"]
const _hoisted_34 = ["label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_Field = _resolveComponent("Field")!

  return (_ctx.selectOptions && _ctx.compareLists)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.translate("REPORT_COMPARISON_SECTION_HEADER")), 1),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.formData.historical_mode == 'NO')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_BarChart, {
                  chartData: _ctx.chartData,
                  options: _ctx.options,
                  height: "500"
                }, null, 8, ["chartData", "options"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_BarChart, {
                  chartData: _ctx.chartData1,
                  options: _ctx.options1,
                  height: "500"
                }, null, 8, ["chartData", "options"])
              ])),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.translate("LABEL_COMPARE_SURVEY_PLUS_CHART")), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.translate("REPORT_SELECT_EMPLOYEES_LABEL")), 1),
                _createVNode(_component_Field, {
                  as: "select",
                  name: "employees_range",
                  class: "form-select form-select-solid form-select-lg",
                  modelValue: _ctx.formData.employees_range,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.employees_range) = $event)),
                  "aria-label": _ctx.translate('REPORT_SELECT_EMPLOYEES_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", {
                      value: 0,
                      label: _ctx.translate('REPORT_COMPARISON_ALL_EMPLOYEES_RANGES')
                    }, null, 8, _hoisted_11),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions.employee_range, (item, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: item.range_id,
                        label: item.description,
                        key: index
                      }, null, 8, _hoisted_12))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.translate("REPORT_SELECT_SETTORE_LABEL")), 1),
                _createVNode(_component_Field, {
                  as: "select",
                  name: "settori",
                  class: "form-select form-select-solid form-select-lg",
                  modelValue: _ctx.formData.settore,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.settore) = $event)),
                  "aria-label": _ctx.translate('REPORT_SELECT_SETTORE_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", {
                      value: 0,
                      label: _ctx.translate('REPORT_COMPARISON_ALL_SECTORS')
                    }, null, 8, _hoisted_15),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions.settori, (item, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: item.id,
                        label: item[_ctx.translateKey('descrizione')],
                        key: index
                      }, null, 8, _hoisted_16))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.translate("REPORT_SELECT_FATTURATO_LABEL")), 1),
                _createVNode(_component_Field, {
                  as: "select",
                  name: "fatturati",
                  class: "form-select form-select-solid form-select-lg",
                  modelValue: _ctx.formData.fatturato,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.fatturato) = $event)),
                  "aria-label": _ctx.translate('REPORT_SELECT_FATTURATO_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", {
                      value: 0,
                      label: _ctx.translate('REPORT_COMPARISON_ALL_REVENUES')
                    }, null, 8, _hoisted_19),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions.fatturati, (item, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: item.id,
                        label: item.descrizione,
                        key: index
                      }, null, 8, _hoisted_20))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.translate("REPORT_SELECT_QUOTED_LABEL")), 1),
                _createVNode(_component_Field, {
                  as: "select",
                  name: "is_quoted",
                  class: "form-select form-select-solid form-select-lg",
                  modelValue: _ctx.formData.is_quoted,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.is_quoted) = $event)),
                  "aria-label": _ctx.translate('REPORT_SELECT_QUOTED_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", {
                      value: "ALL",
                      label: _ctx.translate('REPORT_COMPARISON_INDIFFERENT')
                    }, null, 8, _hoisted_23),
                    _createElementVNode("option", {
                      value: "YES",
                      label: _ctx.translate('REPORT_COMPARISON_QUOTED_MESSAGE')
                    }, null, 8, _hoisted_24),
                    _createElementVNode("option", {
                      value: "NO",
                      label: _ctx.translate('REPORT_COMPARISON_NOT_QUOTED_MESSAGE')
                    }, null, 8, _hoisted_25)
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.translate("REPORT_SELECT_ASSOCIATED_LABEL")), 1),
                _createVNode(_component_Field, {
                  as: "select",
                  name: "is_associated",
                  class: "form-select form-select-solid form-select-lg",
                  modelValue: _ctx.formData.is_associated,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.is_associated) = $event)),
                  "aria-label": _ctx.translate('REPORT_SELECT_ASSOCIATED_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", {
                      value: "ALL",
                      label: _ctx.translate('REPORT_COMPARISON_INDIFFERENT')
                    }, null, 8, _hoisted_28),
                    _createElementVNode("option", {
                      value: "YES",
                      label: _ctx.translate('REPORT_COMPARISON_ASSOCIATED_MESSAGE')
                    }, null, 8, _hoisted_29),
                    _createElementVNode("option", {
                      value: "NO",
                      label: _ctx.translate('REPORT_COMPARISON_NOT_ASSOCIATED_MESSAGE')
                    }, null, 8, _hoisted_30)
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.translate("REPORT_HISTORIC_SELECT_LABEL")), 1),
                _createVNode(_component_Field, {
                  as: "select",
                  name: "historical_mode",
                  class: "form-select form-select-solid form-select-lg",
                  modelValue: _ctx.formData.historical_mode,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.historical_mode) = $event)),
                  "aria-label": _ctx.translate('REPORT_HISTORIC_SELECT_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", {
                      value: "YES",
                      label: _ctx.translate('REPORT_COMPARISON_HISTORICAL_MODE_ACTIVE_MESSAGE')
                    }, null, 8, _hoisted_33),
                    _createElementVNode("option", {
                      value: "NO",
                      label: 
                  _ctx.translate('REPORT_COMPARISON_HISTORICAL_MODE_INACTIVE_MESSAGE') +
                  '(' +
                  _ctx.current_yearly_period +
                  ')'
                
                    }, null, 8, _hoisted_34)
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}