
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { translate, translateKey } from "@/hooks/TranslateService";
import { BarChart } from "vue-chart-3";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import GenerationTable from "@/components/valored/report/GenerationTable.vue";

import {
  IGenerationMix,
  IGenerationMixList,
} from "@/store/modules/valored/ReportModule";
import router from "@/router";

export default defineComponent({
  name: "CompareResult",
  components: { BarChart, GenerationTable },
  setup() {
    const store = useStore();
    const route = useRoute();
    const historical_mode = ref<boolean>(false);
    const menString = translate("SURVEY_MEN_STRING");
    const womenString = translate("SURVEY_WOMEN_STRING");

    const generationMixList = reactive(
      computed(() => store.getters.getGenerationMixList)
    );

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const selectedYear = ref<number>(current_yearly_period.value);

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    const chartData = reactive(
      computed(() => {
        if (generationMixList.value) {
          if (selectedYear.value) {
            return {
              labels: ["18-29", "30-49", ">50"],
              datasets: [
                {
                  label: womenString,
                  data: Object.values(
                    generationMixList.value[selectedYear.value].DONNE
                  ),
                  backgroundColor: "#DDF0FF",
                  labelUp: Object.values(
                    generationMixList.value[selectedYear.value].labelUp
                  ),
                },
                {
                  label: menString,
                  data: Object.values(
                    generationMixList.value[selectedYear.value].UOMINI
                  ),
                  backgroundColor: "#869FB3",
                  labelUp: Object.values(
                    generationMixList.value[selectedYear.value].labelUp
                  ),
                },
              ],
            };
          }
        }
        return null;
      })
    );

    const option = reactive(
      computed(() => {
        return {
          plugins: {
            legend: {
              position: "bottom",
            },
            datalabels: {
              align: "top",
              anchor: "end",
              offset: 5,
              color: function (context) {
                return "#595959";
              },
              overlap: false,
              font: function (context) {
                var w = context.chart.width;
                return {
                  size: w < 412 ? 10 : 14,
                  weight: 700,
                };
              },
              formatter: function (value, context) {
                var index = context.dataIndex;
                if (context.dataset.type === "line") return "";
                else {
                  if (context.dataset.label == menString) {
                    var label = context.dataset.labelUp[index].toFixed(1) + "%";
                    return label;
                  }
                  return null;
                }
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              max: 100,
            },
          },
        };
      })
    );

    const subLabelArr = reactive(
      computed(() => {
        if (generationMixList.value) {
          const yearArr = Object.keys(generationMixList.value).slice(-3);
          return [...yearArr, ...yearArr, ...yearArr];
        }
        return [];
      })
    );

    const optionForMultiYear = reactive(
      computed(() => {
        return {
          plugins: {
            title: {
              display: false,
            },
            legend: false,

            datalabels: {
              align: "top",
              anchor: "end",
              offset: 5,
              color: function (context) {
                return "#595959";
              },
              overlap: false,
              font: function (context) {
                var w = context.chart.width;
                return {
                  size: w < 412 ? 10 : 14,
                  weight: 700,
                };
              },
              formatter: function (value, context) {
                var index = context.dataIndex;
                if (context.dataset.type === "line") return "";
                else {
                  if (context.dataset.label == menString) {
                    var label = context.dataset.labelUp[index].toFixed(1) + "%";
                    return label;
                  }
                  return null;
                }
              },
            },
          },
          tooltips: {
            mode: "nearest",
            axis: "x",
            itemSort: function (a, b) {
              return b.datasetIndex - a.datasetIndex;
            },
            backgroundColor: "rgba(0,0,0,0.6)",
            callbacks: {
              title: function (tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              },
              label: function (tooltipItems, data) {
                return (
                  data.datasets[tooltipItems.datasetIndex].label +
                  ": " +
                  tooltipItems.yLabel +
                  "%"
                );
              },
            },
          },
          showTooltips: true,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              labels: subLabelArr.value,
              type: "category",
              categoryPercentage: 0.9,
              id: "yAxis1",
              gridLines: {
                display: false,
              },
              stacked: true,
            },
            y: {
              stacked: true,
              type: "linear",
              gridLines: {
                display: false,
              },
              max: 100,
            },
          },
        };
      })
    );

    const chartDataForMultiYear = reactive(
      computed(() => {
        if (generationMixList.value) {
          let data = [] as unknown[];
          Object.keys(generationMixList.value)
            .slice(-3)
            .forEach((item: string) => {
              const generation = generationMixList.value[
                item
              ] as IGenerationMix;
              data.push({
                label: womenString,
                data: [
                  generation.DONNE.young,
                  generation.DONNE.mid,
                  generation.DONNE.old,
                ],
                backgroundColor: "#DDF0FF",
                stack: item,
                xAxisID: "yAxis1",
                tension: 2.6,
                categoryPercentage: 1,
                barPercentage: 0.7,
                labelUp: [
                  generation.labelUp.young,
                  generation.labelUp.mid,
                  generation.labelUp.old,
                ],
              });
              data.push({
                label: menString,
                data: [
                  generation.UOMINI.young,
                  generation.UOMINI.mid,
                  generation.UOMINI.old,
                ],
                backgroundColor: "#869FB3",
                stack: item,
                xAxisID: "yAxis1",
                tension: 2.6,
                categoryPercentage: 1,
                barPercentage: 0.7,
                labelUp: [
                  generation.labelUp.young,
                  generation.labelUp.mid,
                  generation.labelUp.old,
                ],
              });
            });
          return {
            labels: ["18-29", "30-49", ">50"],
            datasets: data,
          };
        }

        return null;
      })
    );

    onMounted(() => {
      getData();
    });

    /** Methods */
    const getData = () => {
      if (isAdmin.value) {
        store.dispatch(
          Actions.REQ_GENERATION_MIX_REPORT,
          {
            user_id: route.params.id
          }
        );
      } else {
        store.dispatch(
          Actions.REQ_GENERATION_MIX_REPORT,
          {
            user_id: null
          }
        );
      } 
    };

    return {
      translate,
      translateKey,
      option,
      chartData,
      chartDataForMultiYear,
      optionForMultiYear,
      current_yearly_period,
      historical_mode,
    };
  },
});
