
import { defineComponent, computed, reactive, ref } from "vue";
import { translate } from "@/hooks/TranslateService";
import { BarChart } from "vue-chart-3";
import { useStore } from "vuex";

export default defineComponent({
  name: "overallResult",
  components: { BarChart },
  setup() {
    const store = useStore();
    const barRef = ref();

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const averageReport = reactive(
      computed(() => store.getters.getAverageReport)
    );

    const reportLists = reactive(computed(() => store.getters.getReportLists));

    const currentReport = reactive(
      computed(() => {
        if (reportLists.value) {
          return reportLists.value[current_yearly_period.value];
        } else return null;
      })
    );

    const avgReport = reactive(
      computed(() => {
        if (averageReport.value)
          return averageReport.value[current_yearly_period.value];
        else return null;
      })
    );

    const options = reactive(
      computed(() => {
        return {
          indexAxis: "y",
          plugins: {
            tooltip: {
              display: false,
            },
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                line1: {
                  type: "line",
                  xMin: avgReport.value
                    ? (avgReport.value.III * 100).toFixed(1)
                    : 0,
                  xMax: avgReport.value
                    ? (avgReport.value.III * 100).toFixed(1)
                    : 0,
                  borderColor: "rgb(255, 99, 132)",
                  borderWidth: 2,
                  label: {
                    enable: true,
                    content: "This value",
                  },
                },
                label1: {
                  type: "label",
                  xValue: avgReport.value
                    ? (avgReport.value.III * 100).toFixed(1)
                    : 0,
                  yValue: 0,
                  xAdjust: 80,
                  yAdjust: -70,
                  backgroundColor: "rgba(255,255,255)",
                  content: avgReport.value
                    ? translate("REPORT_AVERAGE_VALUE_STRING") +
                      "=" +
                      (avgReport.value.III * 100).toFixed(1)
                    : "",
                  textAlign: "start",
                  font: {
                    size: 14,
                  },
                },
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              suggestedMin: 50,
              suggestedMax: 100,
            },
            y: {
              stacked: true,
            },
          },
          elements: {
            bar: {
              borderColor: "#454545",
              backgroundColor: "rgba(255,255,255)",
              borderWidth: 2,
            },
          },
        };
      })
    );

    const chartData = reactive(
      computed(() => {
        return {
          labels: [""],
          datasets: [
            {
              data: [
                currentReport.value
                  ? (currentReport.value.III * 100).toFixed(1)
                  : 0,
              ],
              barThickness: 50,
              backgroundColor: ["#d3dbde"],
            },
            {
              data: [
                currentReport.value
                  ? (100 - currentReport.value.III * 100).toFixed(1)
                  : 0,
              ],
              barThickness: 50,
            },
          ],
        };
      })
    );

    return {
      translate,
      avgReport,
      currentReport,
      options,
      chartData,
      barRef,
      current_yearly_period,
    };
  },
});
