
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import SimpleTable from "@/components/valored/table/SimpleTable.vue";

export default defineComponent({
  name: "CompareResult",
  props: ["historical_mode"],
  components: { SimpleTable },
  setup() {
    const store = useStore();
    const route = useRoute();
    const generationMixTableList = reactive(
      computed(() => store.getters.getGenerationMixTableList)
    );

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const tableData = reactive(
      computed(() => {
        if (generationMixTableList.value) {
          return generationMixTableList.value[current_yearly_period.value];
        }
        return null;
      })
    );

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    onMounted(() => {
      getData();
    });

    /** Methods */
    const getData = () => {
      if (isAdmin.value) {
        store.dispatch(
          Actions.REQ_GENERATION_MIX_TABLE,
          {
            user_id: route.params.id
          }
        );
      } else {
        store.dispatch(
          Actions.REQ_GENERATION_MIX_TABLE,
          {
            user_id: null
          }
        );  
      } 
    };

    return {
      translate,
      translateKey,
      generationMixTableList,
      tableData,
    };
  },
});
