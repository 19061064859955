import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-10 p-10 print-mt-0 print-pt-0 card" }
const _hoisted_2 = {
  key: 0,
  class: "h2 text-red"
}
const _hoisted_3 = {
  key: 1,
  class: "overall-p mt-8"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", null, [
      _createTextVNode(_toDisplayString(_ctx.translate("REPORT_III_RESULT_HEADER")) + " ", 1),
      (_ctx.currentReport && _ctx.avgReport)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString((_ctx.currentReport.III * 100).toFixed(1)) + "/100 ", 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.currentReport && _ctx.avgReport)
      ? (_openBlock(), _createBlock(_component_BarChart, {
          key: 0,
          ref: "barRef",
          chartData: _ctx.chartData,
          options: _ctx.options,
          height: "250",
          id: "overallBarChart"
        }, null, 8, ["chartData", "options"]))
      : _createCommentVNode("", true),
    (_ctx.currentReport && _ctx.avgReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", null, [
            (_ctx.currentReport.III < _ctx.avgReport.III)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  innerHTML: _ctx.translate('REPORT_III_UNDER_AVERAGE_FEEDBACK')
                }, null, 8, _hoisted_4))
              : (_ctx.currentReport.III * 1 < _ctx.avgReport.III * 1 + 0.1)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _ctx.translate('REPORT_III_IN_THE_AVERAGE_FEEDBACK')
                  }, null, 8, _hoisted_5))
                : (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    innerHTML: _ctx.translate('REPORT_III_OVER_AVERAGE_FEEDBACK')
                  }, null, 8, _hoisted_6)),
            ((_ctx.currentReport.Paygap_Bonus))
              ? (_openBlock(), _createElementBlock("span", {
                  key: 3,
                  innerHTML: _ctx.translate('REPORT_BONUS_PAYGAP_MESSAGE_FIRST_PART')+' '+_ctx.currentReport.Paygap_Bonus*100+' '+_ctx.translate('REPORT_BONUS_PAYGAP_MESSAGE_LAST_PART')
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}