
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { translate } from "@/hooks/TranslateService";
import { PolarAreaChart } from "vue-chart-3";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Popper from "vue3-popper";

export default defineComponent({
  name: "IndicatorsDetail",
  components: { PolarAreaChart, Popper },
  setup() {
    const { locale } = useI18n();
    const store = useStore();

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const avgReport = reactive(
      computed(() => {
        if (store.getters.getAverageReport)
          return store.getters.getAverageReport[current_yearly_period.value];
        else return null;
      })
    );

    const reportLists = reactive(computed(() => store.getters.getReportLists));

    const currentReport = reactive(
      computed(() => {
        if (reportLists.value)
          return reportLists.value[current_yearly_period.value];
        return null;
      })
    );

    const indicatorList = reactive(
      computed(() => [
        {
          title: "GOVERNANCE:",
          value: currentReport.value && currentReport.value.Governance !== undefined
            ? (currentReport.value.Governance * 100).toFixed(1)
            : 0,
          description: "REPORT_INDEX_RESULTS_FIXED_GOVERNANCE_DESCRIPTION",
          img_url: "/media/valored/icon_governance_new.png",
          background_url: "/media/shape/governance-yellow-shape.png",
          color: "#FFFFFF",
          backgroundColor: "#033A5C",
          tooltip:
            avgReport.value?.Governance < currentReport.value?.Governance
              ? "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_GOVERNANCE"
              : "REPORT_INDEX_RESULTS_FEEDBACK_BAD_GOVERNANCE",
        },
        {
          title: "ATTRACTIVENESS:",
          value: currentReport.value
            ? (currentReport.value.Attractiveness * 100).toFixed(1)
            : 0,
          description: "REPORT_INDEX_RESULTS_FIXED_ATTRACTIVENESS_DESCRIPTION",
          img_url: "/media/valored/icon_attractivness_new.png",
          background_url: "/media/shape/attractiveness-red-shape.png",
          color: "#FFFFFF",
          backgroundColor: "#033A5C",
          tooltip:
            avgReport.value?.Attractiveness <
            currentReport.value?.Attractiveness
              ? "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_ATTRACTIVENESS"
              : "REPORT_INDEX_RESULTS_FEEDBACK_BAD_ATTRACTIVENESS",
        },
        {
          title: "DEVELOPMENT:",
          value: currentReport.value
            ? (currentReport.value.Development * 100).toFixed(1)
            : 0,
          description: "REPORT_INDEX_RESULTS_FIXED_DEVELOPMENT_DESCRIPTION",
          img_url: "/media/valored/icon_development_new.png",
          background_url: "/media/shape/development-green-shape.png",
          color: "#FFFFFF",
          backgroundColor: "#033A5C",
          tooltip:
            avgReport.value?.Development < currentReport.value?.Development
              ? "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_DEVELOPMENT"
              : "REPORT_INDEX_RESULTS_FEEDBACK_BAD_DEVELOPMENT",
        },
        {
          title: "RETAINMENT:",
          value: currentReport.value
            ? (currentReport.value.Retainment * 100).toFixed(1)
            : 0,
          description: "REPORT_INDEX_RESULTS_FIXED_RETAINMENT_DESCRIPTION",
          img_url: "/media/valored/icon_retainment_new.png",
          background_url: "/media/shape/retainment-pink-shape.png",
          color: "#FFFFFF",
          backgroundColor: "#033A5C",
          tooltip:
            avgReport.value?.Retainment < currentReport.value?.Retainment
              ? "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_RETAINMENT"
              : "REPORT_INDEX_RESULTS_FEEDBACK_BAD_RETAINMENT",
        },
      ])
    );

    const options = reactive(
      computed(() => {
        return {
          interaction: {
            intersect: true,
            mode: "index",
          },
          scale: {
            r: {
              min: 0,
              max: 100,
            },
          },
          responsive: false,
          plugins: {
            htmlLegend: {
              // ID of the container to put the legend in
              containerID: "legend-container",
            },
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,0.6)",
              callbacks: {
                title: function (title) {
                  return title[0].label;
                },
                label: function (context) {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.r !== null) {
                    label += context.parsed.r;
                  }
                  return label;
                },
              },
            },
            segmentShowStroke: false,

            datalabels: {
              align: "top",
              anchor: "end",
              offset: 5,
              color: function (context) {
                //return context.dataset.backgroundColor;
                return "#595959";
              },
              overlap: false,
              font: function (context) {
                var w = context.chart.width;
                return {
                  size: w < 412 ? 10 : 14,
                  weight: 700,
                };
              },
              formatter: function (value, context) {
                var index = context.dataIndex;
                if (context.dataset.type === "line") return "";
                else {
                  if (context.dataset.label == "UOMINI") {
                    var label = context.dataset.labelUp[index].toFixed(1) + "%";
                    return label;
                  }
                  return null;
                }
              },
            },
          },
        };
      })
    );

    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = document.getElementById(id);
      if (legendContainer) {
        let listContainer = legendContainer.querySelector("ul");
        if (!listContainer) {
          listContainer = document.createElement("ul");
          // listContainer.style.display = "flex";
          listContainer.style.margin = "0";
          listContainer.style.padding = "0";

          legendContainer.appendChild(listContainer);
        }
        return listContainer;
      }
    };

    const htmlLegendPlugin = {
      id: "htmlLegend",
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        if (ul) {
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove();
          }
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        var userValues = chart.data.datasets[1];

        items.forEach((item) => {
          const li = document.createElement("li");
          li.style.cursor = "pointer";
          li.style.display = "flex";
          li.style.marginLeft = "10px";
          li.style.marginBottom = "10px";

          li.onclick = () => {
            chart.toggleDataVisibility(item.index);
            chart.update();
          };

          // Color box
          const boxSpan = document.createElement("span");
          boxSpan.style.background = userValues.backgroundColor[item.index];
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + "px";
          boxSpan.style.display = "inline-block";
          boxSpan.style.height = "20px";
          boxSpan.style.marginRight = "10px";
          boxSpan.style.width = "20px";
          boxSpan.style.marginTop = "5px";

          // Text
          const divContainer = document.createElement("div");

          const textContainer = document.createElement("p");
          textContainer.style.fontSize = "18px";
          textContainer.style.margin = "0";
          textContainer.style.padding = "0";
          textContainer.style.textDecoration = item.hidden
            ? "line-through"
            : "";
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          const textContainer2 = document.createElement("p");
          textContainer2.style.margin = "0";
          textContainer2.style.padding = "0";
          const avgValue = avgReport.value
            ? avgReport.value[
                item.text[0].toUpperCase() + item.text.slice(1).toLowerCase()
              ]
            : 0;

          const avgTextLabel =
            locale.value == "it"
              ? "Risultato medio indice"
              : "Average index score";

          const avgText =
            "(" + avgTextLabel + " = " + (avgValue * 100).toFixed(1) + ")";
          const text2 = document.createTextNode(avgText);
          textContainer2.appendChild(text2);

          divContainer.appendChild(textContainer);
          divContainer.appendChild(textContainer2);

          li.appendChild(boxSpan);
          li.appendChild(divContainer);
          if (ul) ul.appendChild(li);
        });
      },
    };

    const plugins = reactive(
      computed(() => {
        return [htmlLegendPlugin];
      })
    );

    const chartData = reactive(
      computed(() => {
        let currentValueArr = ["0", "0", "0", "0"] as Array<string>;
        let avgValueArr = ["0", "0", "0", "0"] as Array<string>;
        if (currentReport.value) {
          currentValueArr = [
            (currentReport.value.Governance * 100).toFixed(1),
            (currentReport.value.Attractiveness * 100).toFixed(1),
            (currentReport.value.Development * 100).toFixed(1),
            (currentReport.value.Retainment * 100).toFixed(1),
          ];
        }
        if (avgReport.value) {
          avgValueArr = [
            (avgReport.value.Governance * 100).toFixed(1),
            (avgReport.value.Attractiveness * 100).toFixed(1),
            (avgReport.value.Development * 100).toFixed(1),
            (avgReport.value.Retainment * 100).toFixed(1),
          ];
        }
        return {
          labels: ["GOVERNANCE", "ATTRACTIVENESS", "DEVELOPMENT", "RETAINMENT"],
          datasets: [
            {
              fill: false,
              label: translate("REPORT_INDEX_CHART_AVG_TOOLTIP_LABEL"),
              data: avgValueArr,
              borderColor: ["#4D4D4D", "#4D4D4D", "#4D4D4D", "#4D4D4D"],
            },
            {
              label: translate("REPORT_INDEX_CHART_YOUR_SCORE_TOOLTIP_LABEL"),
              data: currentValueArr,
              backgroundColor: ["#FDD766", "#86012C", "#297A6C", "#FFA5C2"],
              // borderWidth: "0",
              borderWidth: "0.01",
            },
          ],
        };
      })
    );

    const convertedValue = (value) => {
      if (isAdmin.value) {
        return value;
      }
      return Number(value) > 100 ? (100).toFixed(1) : value;
    };

    return {
      translate,
      avgReport,
      indicatorList,
      options,
      chartData,
      plugins,
      convertedValue,
    };
  },
});
