
import {
  defineComponent,
  computed,
  reactive,
  ref,
  watch,
  onMounted,
} from "vue";
import { translate, translateKey } from "@/hooks/TranslateService";
import { BarChart } from "vue-chart-3";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";

interface IFormData {
  employees_range: number;
  settore: number;
  fatturato: number;
  is_quoted: string;
  is_associated: string;
  historical_mode: string;
}

export default defineComponent({
  name: "CompareResult",
  components: { 
    BarChart,
    Field,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const barRef = ref();

    const getFixedNum = (number) => {
      return (number * 100).toFixed(1);
    };

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const formData = ref<IFormData>({
      employees_range: 0,
      settore: 0,
      fatturato: 0,
      is_quoted: "ALL",
      is_associated: "ALL",
      historical_mode: "NO",
    });

    const avgReport = reactive(
      computed(() => {
        if (store.getters.getAverageReport)
          return store.getters.getAverageReport[current_yearly_period.value];
        else return null;
      })
    );

    const avgReportList = reactive(
      computed(() => {
        if (store.getters.getAverageReportList) {
          return store.getters.getAverageReportList;
        }
        return null;
      })
    );

    const companyReportList = reactive(
      computed(() => {
        if (store.getters.getReportLists) {
          return store.getters.getReportLists;
        }
        return null;
      })
    );

    const reportLists = reactive(computed(() => store.getters.getReportLists));

    const subLabelArr = reactive(
      computed(() => {
        if (avgReportList.value) {
          const yearArr = Object.keys(avgReportList.value).slice(-3);
          return [...yearArr, ...yearArr, ...yearArr, ...yearArr, ...yearArr];
        }
        return [];
      })
    );

    const currentCompanyReport = reactive(
      computed(() => {
        if (reportLists.value && current_yearly_period.value) {
          return reportLists.value[current_yearly_period.value];
        }
        return null;
      })
    );

    const compareLists = reactive(
      computed(() => store.getters.getCompareLists)
    );

    const avgChartArray = reactive(
      computed(() => {
        if (compareLists.value) {
          let overallArr: string[] = [];
          let goverArr: string[] = [];
          let attrArr: string[] = [];
          let devArr: string[] = [];
          let retainArr: string[] = [];
          Object.keys(compareLists.value)
            .slice(-3)
            .map((year) => {
              overallArr.push((compareLists.value[year].III * 100).toFixed(1));
              goverArr.push(
                (compareLists.value[year].Governance * 100).toFixed(1)
              );
              attrArr.push(
                (compareLists.value[year].Attractiveness * 100).toFixed(1)
              );
              devArr.push(
                (compareLists.value[year].Development * 100).toFixed(1)
              );
              retainArr.push(
                (compareLists.value[year].Retainment * 100).toFixed(1)
              );
            });
          return [
            ...overallArr,
            ...goverArr,
            ...attrArr,
            ...devArr,
            ...retainArr,
          ];
        }
        return null;
      })
    );

    const reportChartArray = reactive(
      computed(() => {
        if (avgReportList.value) {
          let tempArr: Array<any> = [];
          Object.keys(companyReportList.value)
            .slice(-3)
            .map((year) => {
              tempArr.push({
                data: [
                  getFixedNum(companyReportList.value[year].III),
                  getFixedNum(companyReportList.value[year].Governance),
                  getFixedNum(companyReportList.value[year].Attractiveness),
                  getFixedNum(companyReportList.value[year].Development),
                  getFixedNum(companyReportList.value[year].Retainment),
                ],
                backgroundColor: [
                  "#F2F2F2",
                  "#FDD766",
                  "#86012C",
                  "#297A6C",
                  "#FFA5C2",
                ],
                borderColor: [
                  "#4D4D4D",
                  "#4D4D4D",
                  "#4D4D4D",
                  "#4D4D4D",
                  "#4D4D4D",
                ],
                hoverColor: [
                  "rgba(155, 227, 191, 0.4)",
                  "rgba(36,76,90,0.4)",
                  "rgba(191,122,203,0.4)",
                  "rgba(52,146,129,0.4)",
                  "rgba(255,184,28,0.4)",
                ],
                label: year,
                xAxisID: "yAxis1",
                tension: 2.6,
                categoryPercentage: 1,
                barPercentage: 0.7,
              });
            });
          return tempArr;
        }
        return [];
      })
    );

    const selectOptions = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const currentReport = reactive(
      computed(() => {
        if (compareLists.value) return compareLists.value;
        return null;
      })
    );

    const title = reactive(
      computed(() => {
        if (currentReport.value && currentReport.value.counter >=0) {
          return (
            t("REPORT_COMPARISON_CHART_TITLE_WITH_COMPANY_#") +
            currentReport.value.counter
          );
        }
        return "";
      })
    );

    const options = reactive(
      computed(() => {
        return {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title.value,
            },
            legend: {
              labels: {
                filter: function (legendItem, data) {
                  if (legendItem.text !== t("REPORT_COMPARISON_CHART_AVG_TOOLTIP"))
                    return false;
                  return legendItem.text;
                },
                usePointStyle: true,
                pointStyle: "line",
              },
            },
            tooltip: {
              itemSort: function (a, b) {
                return b.datasetIndex - a.datasetIndex;
              },
            },
            datalabels: {
              align: "top",
              anchor: "end",
              offset: 5,
              color: function (context) {
                //return context.dataset.backgroundColor;
                return "#595959";
              },
              overlap: false,
              font: function (context) {
                var w = context.chart.width;
                return {
                  size: w < 412 ? 10 : 14,
                  weight: 700,
                };
              },
              formatter: function (value, context) {
                var index = context.dataIndex;
                if (context.dataset.type === "line") return "";
                else {
                  if (context.dataset.label == "UOMINI") {
                    var label = context.dataset.labelUp[index].toFixed(1) + "%";
                    return label;
                  }
                  return null;
                }
              },
            },
          },
          interaction: {
            intersect: true,
            mode: "index",
          },
          scales: {
            y: {
              suggestedMax: 100,
              max: 100,
            },
          },
        };
      })
    );

    const options1 = reactive(
      computed(() => {
        return {
          responsive: true,
          plugins: {
            title: {
              display: false,
              text: title.value,
            },
            legend: false,
            datalabels: false,
            tooltip: {
              itemSort: function (a, b) {
                return b.datasetIndex - a.datasetIndex;
              },
              callbacks: {
                title: function (title) {
                  return title[0].label;
                },
                label: function (context) {
                  let label = context.dataset.label || "";

                  if (label != "Average") label = "Company Score";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                },
              },
            },
          },
          interaction: {
            intersect: true,
            mode: "x",
          },
          scales: {
            y: {
              suggestedMax: 100,
              max: 100,
              gridLines: {
                show: false,
              },
            },
            x: {
              labels: subLabelArr.value,
              type: "category",
              categoryPercentage: 1,
              id: "yAxis1",
              gridLines: {
                show: false,
              },
            },
          },
        };
      })
    );

    const currentComparedData = reactive(
      computed(() => {
        if (currentReport.value) {
          return [
            (currentReport.value.III * 100).toFixed(1),
            (currentReport.value.Governance * 100).toFixed(1),
            (currentReport.value.Attractiveness * 100).toFixed(1),
            (currentReport.value.Development * 100).toFixed(1),
            (currentReport.value.Retainment * 100).toFixed(1),
          ];
        }
        return [0, 0, 0, 0, 0];
      })
    );

    const currentCompanyReportData = reactive(
      computed(() => {
        if (currentCompanyReport.value) {
          return [
            (currentCompanyReport.value.III * 100).toFixed(1),
            (currentCompanyReport.value.Governance * 100).toFixed(1),
            (currentCompanyReport.value.Attractiveness * 100).toFixed(1),
            (currentCompanyReport.value.Development * 100).toFixed(1),
            (currentCompanyReport.value.Retainment * 100).toFixed(1),
          ];
        }
        return [0, 0, 0, 0, 0];
      })
    );

    const chartData = reactive(
      computed(() => {
        return {
          labels: [
            "OVERALL INDEX",
            "GOVERNANCE",
            "ATTRACTIVENESS",
            "DEVELOPMENT",
            "RETAINMENT",
          ],
          datasets: [
            {
              type: "line",
              borderColor: ["rgba(36,76,90,1)"],
              data: currentComparedData.value,
              borderWidth: 2,
              fill: false,
              label: t("REPORT_COMPARISON_CHART_AVG_TOOLTIP"), // for tooltip
              tension: 0.4,
              pointStyle: "circle",
              pointRadius: 2,
            },
            {
              data: currentCompanyReportData.value,
              backgroundColor: [
                "#F2F2F2",
                "#FDD766",
                "#86012C",
                "#297A6C",
                "#FFA5C2",
              ],
              borderColor: [
                "#4D4D4D",
                "#4D4D4D",
                "#4D4D4D",
                "#4D4D4D",
                "#4D4D4D",
              ],
              borderWidth: [2, 0, 0, 0, 0],
              hoverColor: [
                "rgba(155, 227, 191, 0.4)",
                "rgba(36,76,90,0.4)",
                "rgba(191,122,203,0.4)",
                "rgba(52,146,129,0.4)",
                "rgba(255,184,28,0.4)",
              ],
              label: t("REPORT_INDEX_CHART_YOUR_SCORE_TOOLTIP_LABEL"), // for tooltip
            },
          ],
        };
      })
    );

    const chartData1 = reactive(
      computed(() => {
        return {
          labels: [
            "OVERALL INDEX",
            "GOVERNANCE",
            "ATTRACTIVENESS",
            "DEVELOPMENT",
            "RETAINMENT",
          ],
          datasets: [
            {
              label: "Average",
              type: "line",
              borderColor: ["rgba(36,76,90,1)"],
              data: avgChartArray.value,
              fill: false,
              showLine: false,
              pointRadius: 5.5,
              pointHoverRadius: 7.5,
              pointStyle: "rectRot",
              hitRadius: 1,
              hoverRadius: 4,
              tense: 5,
            },
            ...reportChartArray.value,
          ],
        };
      })
    );

    /** Mounted */

    onMounted(() => {
      getCompareReport(formData.value);
    });

    /** Methods */
    const getCompareReport = (data: IFormData) => {
      store.dispatch(Actions.REQ_COMPARE_REPORT, {
        data: {
          ...data,
          comparison_year: current_yearly_period.value,
        },
        user_id: route.params.id ? route.params.id : "",
      });
    };

    watch(formData.value, (val: IFormData) => {
      getCompareReport(val);
    });

    return {
      translate,
      translateKey,
      avgReport,
      compareLists,
      currentReport,
      selectOptions,
      options,
      chartData,
      barRef,
      formData,
      current_yearly_period,
      chartData1,
      options1,
    };
  },
});
